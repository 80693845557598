import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { SiteActionType, useSiteContext } from '../SiteContext';
import { filterPagesByCondition, findPageByCondition } from '../../utils';
import { SitemapEntry } from '../../interfaces';
import { Media } from '@ncs-frontend-monorepo/utils';
import { MetaNavigation } from './MetaNavigation';
import { AreaNavigation } from './AreaNavigation';
import { ViewType } from '../AvailabilityCheck';
import { AvailabilityTypes } from '../AvailabilityCheck/Context';
import { useAvailabilityModal } from '../../hooks/useAvailabilityModal';

const MobileProductMenu = dynamic(() =>
  import('./MobileProductMenu').then(
    ({ MobileProductMenu }) => MobileProductMenu,
  ),
);

const DesktopProductMenu = dynamic(() =>
  import('./DesktopProductMenu').then(
    ({ DesktopProductMenu }) => DesktopProductMenu,
  ),
);

const HIDDEN_PAGES: SitemapEntry['pageId'][] = [411, 471];

const Navigation: React.FC = () => {
  const { state, dispatch } = useSiteContext();
  const { openModal } = useAvailabilityModal();

  const rootLink: SitemapEntry = findPageByCondition(
    state?.sitemap?.pageTree || [],
    (entry) =>
      entry.path ===
      (process.env.NEXT_PUBLIC_SITE_PREFIX
        ? process.env.NEXT_PUBLIC_SITE_PREFIX
        : '') +
        '/',
  );
  const metaNavLinks: SitemapEntry[] = [
    ...(rootLink ? [rootLink] : []),
    ...filterPagesByCondition(
      rootLink?.subpages || [],
      (entry) => entry.display === 'meta',
    ),
  ];

  // TODO: Manually filter out the "Meta"-category for testing purposes
  const firstLevelCategoryItems: SitemapEntry[] =
    rootLink?.subpages.reduce((pages, entry) => {
      if (!HIDDEN_PAGES.includes(entry.pageId) && !entry.hidden) {
        const filteredSubpages = {
          ...entry,
          ...{
            subpages:
              entry.subpages?.filter((subpage) => !subpage.hidden) || [],
          },
        };
        pages = [...pages, filteredSubpages];
      }
      return pages;
    }, []) || [];
  const [categoryTab, setCategoryTab] = useState(
    `${firstLevelCategoryItems[0]?.pageId}`,
  );
  const [selectedMenuTab, selectMenuTab] = useState('');

  const selectCategory = (tabId: string) => {
    setCategoryTab(tabId);
    if (categoryTab !== tabId) {
      dispatch({ type: SiteActionType.CLOSE_MENU });
    }
  };

  const selectMenuCategory = async (tabId: string) => {
    selectMenuTab(tabId);
    if (selectedMenuTab === tabId) {
      dispatch({ type: SiteActionType.TOGGLE_MENU });
    } else {
      dispatch({ type: SiteActionType.OPEN_MENU });
    }
  };

  const subTabs = (pageId: number | string): SitemapEntry[] =>
    firstLevelCategoryItems
      .filter((page) => String(page.pageId) === pageId)
      .shift()?.subpages || [];

  const menuCategories = subTabs(categoryTab);

  const handleAvailabilityCheckClick = () => {
    openModal({
      desiredEntrypoint: ViewType.OPTION_SELECT,
      availabilityType: AvailabilityTypes.Global,
      e2e: 'global-availability-check',
    });
  };

  return (
    <>
      <MetaNavigation
        currentPageID={state.currentPageId}
        rootPageID={rootLink?.pageId}
        items={metaNavLinks}
      />

      <AreaNavigation
        categoryItems={firstLevelCategoryItems}
        selectedCategory={categoryTab}
        onCategorySelection={selectCategory}
        onSubCategorySelection={selectMenuCategory}
        navOpen={state.menuOpen}
        toggleMenu={() => dispatch({ type: SiteActionType.TOGGLE_MENU })}
        onAvailabilityCheckClick={handleAvailabilityCheckClick}
      />
      {firstLevelCategoryItems.length > 0 && (
        <>
          <Media lessThan="m">
            <MobileProductMenu
              isOpen={state.menuOpen}
              categoryItems={firstLevelCategoryItems}
            />
          </Media>
          <Media greaterThanOrEqual="m">
            <DesktopProductMenu
              isOpen={state.menuOpen}
              selectedTab={selectedMenuTab}
              menuCategories={menuCategories}
              onOutsideClick={() =>
                dispatch({ type: SiteActionType.CLOSE_MENU })
              }
            />
          </Media>
        </>
      )}
    </>
  );
};

export { Navigation };
