import React from 'react';
import cx from 'classnames';
import styles from './MetaNavigation.module.scss';
import { SitemapEntry } from '../../../interfaces';
import { NavLink } from '../../NavLink';
import { ServiceLinks } from '../ServiceLinks';

interface MetaNavigationProps {
  rootPageID?: SitemapEntry['pageId'];
  currentPageID?: SitemapEntry['pageId'];
  items?: SitemapEntry[];
}

const MetaNavigation: React.FC<MetaNavigationProps> = React.memo(
  ({ rootPageID, currentPageID, items = [] }) => {
    return (
      <nav className={styles.metaNavigation}>
        <div className={styles.metaNavWrapper}>
          <ul className={styles.metaNavItemList}>
            {items.map((entry) => (
              <li
                key={entry.pageId}
                className={cx(styles.metaNavItemListItem, {
                  'fresnel-greaterThanOrEqual-m':
                    rootPageID && rootPageID === entry.pageId,
                })}
              >
                <NavLink
                  url={entry.url || entry.path}
                  title={entry.title}
                  pageId={entry.pageId}
                  active={currentPageID && currentPageID === entry.pageId}
                  appearance="t7"
                  color="white"
                />
              </li>
            ))}
          </ul>
          <ul className={styles.serviceNavItemList}>
            <ServiceLinks type="meta" />
          </ul>
        </div>
      </nav>
    );
  },
);

export { MetaNavigation };
