import { IconChevron, ImageProps, Spacer, Text } from 'dss-ui-library';
import { Link } from '@ncs-frontend-monorepo/content-library';
import NextImage from 'next/image';
import React from 'react';
import styles from './Teaser.module.scss';

interface TeaserButton {
  type: string;
  text: string;
  contactTrack: string;
  link: {
    url: string;
    text?: string;
    title?: string;
    target?: string;
    pageId: number;
  };
}

export interface Teaser {
  headline?: string;
  subline?: string;
  button: TeaserButton;
  image: ImageProps;
}

export const Teaser: React.FC<Teaser> = ({
  headline,
  subline,
  button,
  image,
}) => {
  return (
    <div className={styles.teaser}>
      <span className={styles.teaserBackground} />
      <NextImage
        className={styles.teaserImage}
        src={image.src}
        alt={image.alt}
        fill
        style={{
          objectFit: 'cover',
        }}
      />
      <div className={styles.teaserContent}>
        {headline && (
          <Spacer block b={1}>
            <Text appearance="t2_2" color="white">
              {headline}
            </Text>
          </Spacer>
        )}
        {subline && (
          <Spacer block b={1}>
            <Text appearance="t6" color="white">
              {subline}
            </Text>
          </Spacer>
        )}
        {button && (
          <Link
            {...button.link}
            e2e={button.contactTrack}
            icon={
              <IconChevron
                rotation="right"
                color="white"
                small
                width={24}
                height={24}
              />
            }
          >
            <Text appearance="t6_2" color="white">
              {button.text}
            </Text>
          </Link>
        )}
      </div>
    </div>
  );
};
