export const toE2E = (value: string) =>
  value?.toLowerCase().replace(/\s{1}/g, '-');

export const toCamelCase = (value: string): string =>
  String(value)
    .toLowerCase()
    .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

export const getElementId = (id: number): string => `elementId-${id}`;

/**
 * Function to cast a TYPO3 configuration value to a boolean.
 * @param value
 * @returns boolean
 */
export function castToBoolean(value: unknown): boolean {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'true' || value === '1';
  }
  return Boolean(value);
}

export const validateChipsetId = (id: string): boolean =>
  id.length === 11 && id.startsWith('00') && /^\d+$/.test(id);
