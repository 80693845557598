import React from 'react';
import { Spacer } from 'dss-ui-library';
import { Link, LinkProps } from '@ncs-frontend-monorepo/content-library';
import styles from '../../DropDown.module.scss';

export interface LinksSectionProps {
  links: LinkProps[];
}

export const LinksSection: React.FC<LinksSectionProps> = ({ links }) => (
  <div>
    {links.map((link, linkIdx) => (
      <Spacer
        b={linkIdx === links.length - 1 ? 0 : 2}
        block
        key={`link-${linkIdx}`}
      >
        <Link
          appearance="t6"
          color="black"
          target={link.target}
          title={link.title}
          url={link.url}
          e2e={`link-${link.pageId}`}
          className={styles.dropdownLink}
        >
          {link.title}
        </Link>
      </Spacer>
    ))}
  </div>
);
