import React from 'react';
import { Text, Grid, Spacer } from 'dss-ui-library';
import { Link } from '@ncs-frontend-monorepo/content-library';
import { calculateColumn2Teaser } from '../calculateColumn2Teaser';
import { Teaser } from '../../../Teaser';
import { DropDownColumnProps, Item, Links } from '../../../../../interfaces';
import styles from '../DropDown.module.scss';

export const Column: React.FC<DropDownColumnProps> = ({
  links,
  teasers = [],
}) => {
  const {
    outerColumnSize,
    addMissingOuterColumns,
    innerColumnSize,
    maxTeasers,
  } = calculateColumn2Teaser(links.length);
  return (
    <Grid fullWidth>
      <Grid.Row>
        <Grid.Column xs={outerColumnSize}>
          <Grid fullWidth>
            <Grid.Row>
              {links.map((links: Links, linksIdx: number) => (
                <Grid.Column key={`links-${linksIdx}`} xs={innerColumnSize}>
                  <Text appearance="t6_2">{links.headline}</Text>
                  <Spacer t={3} block />
                  {links.items.map((item: Item, itemIdx: number) => (
                    <Spacer b={2} block key={`link-${itemIdx}`}>
                      <Link
                        color="black"
                        appearance="t6"
                        url={item.link.url}
                        title={item.link.title}
                        target={item.link.target}
                        e2e={`link-${item.link.pageId}`}
                        className={styles.dropdownLink}
                      >
                        {item.linkText}
                      </Link>
                    </Spacer>
                  ))}
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </Grid.Column>
        {[...new Array(addMissingOuterColumns)].map((_, idx: number) => (
          <Grid.Column key={`filler-${idx}`} xs={3} />
        ))}
        {teasers.slice(0, maxTeasers).map((teaser, teaserIdx) => (
          <Grid.Column xs={3} key={`teaser${teaserIdx}`}>
            <Teaser {...teaser} />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};
