interface Column2Teaser {
  outerColumnSize: number;
  maxTeasers: number;
  addMissingOuterColumns: number;
  innerColumnSize: number;
}

export const calculateColumn2Teaser = (columnCount: number): Column2Teaser => {
  const wholeColumnCount = 4;

  // colums of links
  const outerColumnSize = columnCount * 3;

  // max teasers to show
  const maxTeasers: number = columnCount <= 1 ? 2 : columnCount <= 3 ? 1 : 1;

  // fill columns up between columns and teasers
  const missinOuterColumns = wholeColumnCount - columnCount - maxTeasers;
  const addMissingOuterColumns =
    missinOuterColumns < 0 ? 0 : missinOuterColumns;

  // it's fthe relation to the outer columns
  const innerColumnSize = 12 / columnCount;

  return {
    outerColumnSize,
    maxTeasers,
    addMissingOuterColumns,
    innerColumnSize,
  };
};
