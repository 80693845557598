import cx from 'classnames';
import { PlainLink } from '@ncs-frontend-monorepo/content-library';
import Image from 'next/image';
import React, { memo } from 'react';
import { getEnv, isNC } from '@ncs-frontend-monorepo/utils';
import styles from './Logo.module.scss';
import textNA from './NA_LogoText.svg';
import logoNC from './NC_LogoImage.svg';
import textNC from './NC_LogoText.svg';

interface LogoProps {
  showText?: 'responsive' | 'always';
}

export const Logo: React.FC<LogoProps> = memo(({ showText = 'responsive' }) => {
  const site = getEnv().SITE;
  return (
    <PlainLink
      url={process.env.NEXT_PUBLIC_SITE_PREFIX + '/' || '/'}
      className={styles.link}
      e2e="logo"
      title="Zur Startseite"
    >
      <Image
        className={styles.image}
        src={logoNC}
        alt={site}
        priority
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
      <div
        className={cx(styles.text, {
          [styles.showAlways]: showText === 'always',
        })}
      >
        <Image
          src={isNC() ? textNC : textNA}
          alt={site}
          style={{ display: 'block' }}
          unoptimized
          priority
        />
      </div>
    </PlainLink>
  );
});
