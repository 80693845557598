import React from 'react';
import cx from 'classnames';
import styles from './NavLink.module.scss';
import { PlainLink } from '@ncs-frontend-monorepo/content-library';
import { Text } from 'dss-ui-library';

export type NavLinkAppearance = 't5_2' | 't6' | 't6_2' | 't7' | 't7_2';
export type NavLinkColor = 'blue' | 'white' | 'black';

interface NavLinkProps {
  url: string;
  title: string;
  e2e?: string;
  pageId?: string | number;
  type?: 'meta' | 'area';
  active?: boolean;
  onClick?: () => void;
  appearance?: NavLinkAppearance;
  color?: NavLinkColor;
  icon?: JSX.Element;
  truncateText?: boolean;
}

export const NavLink: React.FC<NavLinkProps> = React.memo(
  ({
    url,
    title,
    pageId,
    e2e,
    type = 'meta',
    active = false,
    onClick,
    appearance = 't5_2',
    color = 'black',
    icon,
    truncateText = false,
  }) => {
    const target =
      !url || url.startsWith('#') || url.startsWith('/') ? null : '_blank';

    return (
      <PlainLink
        className={cx(
          type === 'meta' ? styles.metaNavItem : styles.areaNavItem,
          active && styles.active,
        )}
        url={url}
        e2e={`page-${e2e || pageId || title}`}
        title={title}
        target={target}
        onClick={onClick}
      >
        {icon && <>{icon}</>}
        <Text
          className={truncateText ? styles.truncate : ''}
          appearance={appearance}
          color={color}
        >
          {title}
        </Text>
      </PlainLink>
    );
  },
);
