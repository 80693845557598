import React, { useCallback } from 'react';
import cx from 'classnames';
import { NavLink, NavLinkAppearance, NavLinkColor } from '../../NavLink';
import styles from './ServiceLinks.module.scss';
import {
  IconAvatar,
  IconHeart,
  IconHelp,
  IconPhone,
  IconProps,
  LoadingSpinner,
} from 'dss-ui-library';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { CONTACT_URL } from '../../../constants/urls';
import { useModalContext } from '../../ModalContext';
import dynamic from 'next/dynamic';

export interface ServiceLinksProps {
  type: 'meta' | 'area';
}

interface Color {
  [key: string]: NavLinkColor;
}

interface Appearance {
  [key: string]: NavLinkAppearance;
}

const LoginModal = dynamic(
  () => import('../../Modals/LoginModal').then(({ LoginModal }) => LoginModal),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

export const ServiceLinks: React.FC<ServiceLinksProps> = ({ type }) => {
  const { handleModal } = useModalContext();

  const color: Color = {
    area: 'blue',
    meta: 'white',
  };
  const appearance: Appearance = {
    area: 't7_2',
    meta: 't7',
  };

  const iconProps: IconProps = {
    color: color[type],
    width: 24,
    height: 24,
    className: cx(
      type === 'meta' && styles.metaNavItemIcon,
      type === 'area' && styles.areaNavItemIcon,
    ),
  };

  const handleLoginModal = useCallback(() => {
    handleModal({
      content: <LoginModal />,
      e2e: 'login',
    });
  }, [handleModal]);

  return (
    <>
      {type === 'meta' && (
        <li
          className={cx(styles.serviceLinkMeta, 'fresnel-greaterThanOrEqual-m')}
        >
          <NavLink
            title="Empfehlen & kassieren"
            url={
              isNC()
                ? 'https://www.netcologne.de/privatkunden/empfehlen/'
                : 'https://www.netaachen.de/privatkunden/empfehlen/'
            }
            type={type}
            e2e="Empfehlen-und-Kassieren"
            appearance={appearance[type]}
            color={color[type]}
            icon={<IconHeart {...iconProps} />}
          />
        </li>
      )}
      <li
        className={
          type === 'meta'
            ? cx(styles.serviceLinkMeta, 'fresnel-greaterThanOrEqual-s')
            : 'fresnel-lessThan-s'
        }
      >
        <NavLink
          title="Hilfe"
          url={
            isNC()
              ? 'https://www.netcologne.de/privatkunden/hilfe/'
              : 'https://www.netaachen.de/privatkunden/hilfe/'
          }
          type={type}
          appearance={appearance[type]}
          color={color[type]}
          icon={<IconHelp {...iconProps} />}
        />
      </li>
      <li
        className={
          type === 'meta'
            ? cx(styles.serviceLinkMeta, 'fresnel-greaterThanOrEqual-s')
            : 'fresnel-lessThan-s'
        }
      >
        <NavLink
          title="Login"
          url="#login"
          type={type}
          onClick={handleLoginModal}
          appearance={appearance[type]}
          color={color[type]}
          icon={<IconAvatar {...iconProps} />}
        />
      </li>
      <li
        className={
          type === 'meta'
            ? cx(styles.serviceLinkMeta, 'fresnel-greaterThanOrEqual-s')
            : 'fresnel-lessThan-s'
        }
      >
        <NavLink
          title="Kontakt"
          url={CONTACT_URL}
          type={type}
          appearance={appearance[type]}
          color={color[type]}
          icon={<IconPhone {...iconProps} />}
        />
      </li>
    </>
  );
};
