import React from 'react';
import styles from './IconSection.module.scss';
import { Spacer, Text, ListIcon } from 'dss-ui-library';
import { Link } from './Link';

export interface IconSectionProps {
  link: Link;
  headline: string;
  text: string;
  icon: number;
}

export const IconSection: React.FC<IconSectionProps> = (item) => {
  return (
    <div className={styles.menuIcon}>
      <Spacer r={2}>
        <ListIcon
          iconType={item.icon}
          iconColor="blue"
          scale="medium"
          size="inherit"
        />
      </Spacer>
      <div>
        <Text appearance="t6_2">{item.headline}</Text>
        <Spacer t={1} block />
        <Text appearance="t6">{item.text}</Text>
      </div>
    </div>
  );
};
