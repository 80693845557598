import React from 'react';
import { Spacer, Separator, Text, Grid } from 'dss-ui-library';
import {
  IconSection,
  IconSectionProps,
  LinksSection,
  LinksSectionProps,
} from './Sections';
import { calculateColumn2Teaser } from '../calculateColumn2Teaser';
import { Teaser } from '../../../Teaser';

type Section = IconSectionProps | LinksSectionProps;

interface Column {
  headline: string;
  rows: Section[];
}

interface MenuDropDownRowProps {
  // textLink?: {
  //   text: string;
  //   url: string;
  // };
  textLinkTracking?: string;
  columns: Column[];
  teasers: Teaser[];
}

const isIconSection = (row: Section): row is Section => {
  return (row as IconSectionProps).icon !== undefined;
};

const isLinksSection = (row: Section): row is Section => {
  return (row as LinksSectionProps).links !== undefined;
};

export const Row: React.FC<MenuDropDownRowProps> = ({
  columns,
  teasers = [],
}) => {
  const {
    outerColumnSize,
    addMissingOuterColumns,
    innerColumnSize,
    maxTeasers,
  } = calculateColumn2Teaser(columns.length);

  return (
    <Grid fullWidth>
      <Grid.Row>
        <Grid.Column xs={outerColumnSize}>
          <Grid fullWidth>
            <Grid.Row>
              {columns.map((column: Column, columnIdx: number) => (
                <Grid.Column xs={innerColumnSize} key={`column-${columnIdx}`}>
                  <Text appearance="t6_2">{column.headline}</Text>
                </Grid.Column>
              ))}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Spacer y={2} block>
                  <Separator color="light-grey" height={2} />
                </Spacer>
              </Grid.Column>
            </Grid.Row>
            {[...new Array(columns[0].rows.length)].map(
              (_, columnIdx: number) => {
                return (
                  <Grid.Row key={`column-${columnIdx}`}>
                    {columns.map((_, rowIdx: number) => {
                      const item: Section = columns[rowIdx].rows[columnIdx];
                      const iconSection = item as IconSectionProps;
                      const linksSection = item as LinksSectionProps;
                      return (
                        <Grid.Column key={`row-${rowIdx}`} xs={innerColumnSize}>
                          {item && (
                            <>
                              {isIconSection(item) && (
                                <IconSection {...iconSection} />
                              )}
                              {isLinksSection(item) && (
                                <LinksSection links={linksSection.links} />
                              )}
                              <Spacer t={4} block />
                            </>
                          )}
                        </Grid.Column>
                      );
                    })}
                  </Grid.Row>
                );
              },
            )}
          </Grid>
        </Grid.Column>
        {[...new Array(addMissingOuterColumns)].map((_, idx: number) => (
          <Grid.Column key={`filler-${idx}`} xs={3} />
        ))}
        {teasers.slice(0, maxTeasers).map((teaser, teaserIdx) => (
          <Grid.Column xs={3} key={`teaser${teaserIdx}`}>
            <Teaser {...teaser} />
          </Grid.Column>
        ))}
      </Grid.Row>
      {/* NOT MVP relevanttextLink && (
        <Grid.Row>
          <Grid.Column>
            {textLink.text} {textLinkTracking}
          </Grid.Column>
        </Grid.Row>
      )*/}
    </Grid>
  );
};
