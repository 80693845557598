import classnames from 'classnames/bind';
import {
  IconBurger,
  IconClose,
  IconProps,
  IconVCheck,
  Tab,
  TabPanel,
  Tabs,
  Text,
  useBreakpoints,
} from 'dss-ui-library';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { SitemapEntry } from '../../../interfaces';
import { Media } from '@ncs-frontend-monorepo/utils';
import { Logo } from '../../Logo';
import { NavLink } from '../../NavLink';
import { ServiceLinks } from '../ServiceLinks';
import styles from './AreaNavigation.module.scss';
import { useAvailability } from '@ncs-frontend-monorepo/availability';

const cx = classnames.bind(styles);

const IconVCheckOK = dynamic(
  () => import('dss-ui-library').then(({ IconVCheckOK }) => IconVCheckOK),
  { ssr: false },
);

interface AreaNavigationProps {
  categoryItems?: SitemapEntry[];
  closeMenu?: () => void;
  selectedCategory: string;
  onCategorySelection?: (value: number | string) => void;
  onSubCategorySelection?: (value: number | string) => void;
  toggleMenu?: () => void;
  navOpen?: boolean;
  onAvailabilityCheckClick?: () => void;
}

const AreaNavigation: React.FC<AreaNavigationProps> = React.memo(
  ({
    categoryItems = [],
    toggleMenu,
    navOpen = false,
    selectedCategory,
    onCategorySelection,
    onSubCategorySelection,
    onAvailabilityCheckClick,
  }) => {
    const [checked, setChecked] = useState<boolean>(false);
    const [addressLabel, setAddressLabel] = useState<string>('Adresse prüfen');
    const { address, availability } = useAvailability();
    const subTabs = (pageId: number): SitemapEntry[] =>
      categoryItems.filter((page) => page.pageId === pageId).shift().subpages ||
      [];

    const defaultIconProps: IconProps = {
      width: 24,
      height: 24,
      color: 'blue',
      className: styles.icon,
    };

    const { isS, isXS } = useBreakpoints();
    const iconVCheckProps: IconProps =
      isS || isXS
        ? { ...defaultIconProps }
        : {
            width: 30,
            height: 32,
            color: 'blue',
            className: styles.icon,
          };

    const handleAvailabilityCheckClick = () => {
      onAvailabilityCheckClick?.();
    };

    useEffect(() => {
      setChecked(availability.isValidAddress);
      const hasDisplayableAddress = address?.street && address?.houseNumber;
      setAddressLabel(
        hasDisplayableAddress
          ? `${address.street} ${address.houseNumber}`
          : 'Adresse prüfen',
      );
    }, [address?.street, address?.houseNumber, availability.isValidAddress]);

    return (
      <nav className={styles.areaNavigation}>
        <div className={styles.navigationWrapper}>
          <div className={styles.leftNavigationSection}>
            <div className={styles.mainNavigation}>
              <Logo showText={navOpen ? 'always' : 'responsive'} />
              {/* Partial Main-AreaNavigation for Desktop */}
              <Media
                greaterThanOrEqual="m"
                className={styles.partialMainNavigation}
              >
                <Tabs
                  e2e="main-panel"
                  selected={selectedCategory}
                  onSelected={onCategorySelection}
                  disableAnimation
                >
                  {categoryItems.map((entry) => (
                    <Tab
                      id={`${entry.pageId}`}
                      key={`main-${entry.pageId}`}
                      className={styles.partialMainNavigationTab}
                    >
                      <Text appearance="t6_2">{entry.title}</Text>
                    </Tab>
                  ))}
                </Tabs>
              </Media>
            </div>

            {/* Partial Sub-Main-AreaNavigation for Desktop */}
            <Media greaterThanOrEqual="m" className={styles.subNavigation}>
              {categoryItems.map((entry) => (
                <TabPanel
                  value={selectedCategory}
                  id={`${entry.pageId}`}
                  key={`tabpanel-${entry.pageId}`}
                >
                  <Tabs
                    e2e="sub-panel"
                    onSelected={onSubCategorySelection}
                    disableAnimation
                  >
                    {subTabs(entry.pageId).map((entry) => (
                      <Tab
                        id={`${entry.pageId}`}
                        key={`sub-${entry.pageId}`}
                        className={styles.subNavigationTab}
                      >
                        <Text appearance="t6_2">{entry.title}</Text>
                      </Tab>
                    ))}
                  </Tabs>
                </TabPanel>
              ))}
            </Media>
          </div>
          <ul className={styles.rightNavigationSection}>
            {!navOpen && <ServiceLinks type="area" />}
            <li className={cx(navOpen && 'fresnel-greaterThanOrEqual-s')}>
              <div data-cs-mask>
                <NavLink
                  title={addressLabel}
                  e2e="Verfügbarkeit"
                  truncateText={true}
                  url="#verfuegbarkeit"
                  type="area"
                  onClick={handleAvailabilityCheckClick}
                  appearance="t7_2"
                  color="blue"
                  icon={
                    checked ? (
                      <IconVCheckOK {...iconVCheckProps} />
                    ) : (
                      <IconVCheck {...iconVCheckProps} />
                    )
                  }
                />
              </div>
            </li>
            {/*
            // temp hidden cart and availability link for uselab (PKREL-303)
            <li className={cx(navOpen && 'fresnel-greaterThanOrEqual-s')}>
              <NavLink
                title="Warenkorb"
                url="#warenkorb"
                type="area"
                onClick={() => null}
                appearance="t7_2"
                color="blue"
                icon={
                  <IconShoppingBag
                    className={styles.icon}
                    {...defaultIconProps}
                  />
                }
              />
            </li>
             */}
            <Media lessThan="m">
              {(className, renderChildren) =>
                renderChildren && (
                  <li className={cx(className, styles.menu)}>
                    <NavLink
                      title="Menü"
                      url={null}
                      type="area"
                      active={navOpen}
                      onClick={toggleMenu}
                      appearance="t7_2"
                      color="blue"
                      icon={
                        navOpen ? (
                          <IconClose
                            className={styles.icon}
                            {...defaultIconProps}
                          />
                        ) : (
                          <IconBurger
                            className={styles.icon}
                            {...defaultIconProps}
                          />
                        )
                      }
                    />
                  </li>
                )
              }
            </Media>
          </ul>
        </div>
      </nav>
    );
  },
);

export { AreaNavigation };
