export enum BasketItemType {
  TOTAL = 'TOTAL',
  HOUSE_CONNECTION = 'HOUSE_CONNECTION',
  SUB_TOTAL = 'SUB_TOTAL',
  INTERNET = 'INTERNET',
  TELEPHONY = 'TELEPHONY',
  COM_CENTER_BASIC = 'COM_CENTER_BASIC',
  NET_SERVICE = 'NET_SERVICE',
  INSTALLATION_SERVICE = 'INSTALLATION_SERVICE',
  IAD_DEVICE = 'IAD_DEVICE',
  ONT_DEVICE = 'ONT_DEVICE',
  PROVISIONING = 'PROVISIONING',
  DEVICE_SHIPPING = 'DEVICE_SHIPPING',
  TV = 'TV',
  TV_DEVICE = 'TV_DEVICE',
  TV_DEVICE_BYOD = 'TV_DEVICE_BYOD',
  TV_SHIPPING = 'TV_SHIPPING',
}

export enum BasketPositionType {
  TOTAL = 'TOTAL',
  HOUSE_CONNECTION = 'HOUSE_CONNECTION',
  PRODUCT = 'PRODUCT',
  OPTIONS = 'OPTIONS',
  DEVICE = 'DEVICE',
  PROVISIONING = 'PROVISIONING',
  DEVICE_MULTIPLE = 'DEVICE_MULTIPLE',
}
